import { MutationTree } from 'vuex';
import { IState, IMenuItem } from './state';
import { ActionPayload, IAddMenuItemsPayload  } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

// const setChildrenMutation: MutationHandler<{children: IMenuItem[], id: string}> = (state, { payload }) => {
// 	state.rootMenu.map((item: IMenuItem) => {
// 		if(item.id == payload.id) {
// 			item.children = [...payload.children]
// 		}
// 	})
// }

const addMenuItemsMutation: MutationHandler<IAddMenuItemsPayload> = (state, { payload }) => {
	if(payload.menuItem) {
		Object.assign(payload.menuItem, { children: payload.items})
	}
}
const toggleExpandedMutation: MutationHandler<IMenuItem> = (state, { payload }) => {
	Object.assign(payload, {isExpanded: !payload.isExpanded})
}

const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	addMenuItems: addMenuItemsMutation,
	toggleExpanded: toggleExpandedMutation,
}

export default mutations
