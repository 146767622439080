
import Vue from "vue";
import { IMenuItem } from "../store/state";
import { toggleExpanded } from "../store/actions";

interface MenuItemMethods {
  fetchChildren(): void;
}
interface MenuItemComputed {
  levelClass: any;
}
interface MenuItemProps {
  item: IMenuItem;
  index: number;
}

export default Vue.extend<{}, MenuItemMethods, MenuItemComputed, MenuItemProps>(
  {
    name: "MenuItem",
    props: {
      item: {},
      index: Number,
    },
    methods: {
      fetchChildren() {
        this.$store.dispatch(toggleExpanded(this.$props.item));
      }
    },
    computed: {
      levelClass() {
        return "level-" + this.$props.item.level;
      },
    },
  }
);
