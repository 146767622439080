import { Getter } from "vuex";
import { IState } from './state';

const menuItems: Getter<IState, IState> = (state) => state.menuItems;
const contact: Getter<IState, IState> = (state) => state.contact;

export default {
	menuItems,
	contact
}
