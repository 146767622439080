
import Vue from "vue";
import MenuItem from './MenuItem.vue';
import { IMenuItem } from '../store/state';

interface MenuContainerMethods {
  closeMenu(): void;
}

interface MenuContainerData {
  isMobile: boolean
}

interface MenuContainerComputed {
  menuItems: IMenuItem,
  contact: string,
}

interface MenuContainerProps {
  menuItems: IMenuItem[]
}

export default Vue.extend<MenuContainerData, MenuContainerMethods, MenuContainerComputed, MenuContainerProps>({
  name: "MenuContainer",
  data() {
    return {
      isMobile: false
    }
  },
  computed: {
    menuItems() {
      return this.$store.getters.menuItems;
    },
    contact() {
      return this.$store.state.contact;
    }
  },
  methods: {
    closeMenu() {
      let menuContainer = document.querySelector('#menuContainer');
      let menuBtn = document.querySelector('#menuBtn');
      (menuContainer as HTMLElement).setAttribute('data-open', 'false');
      (menuBtn as HTMLButtonElement).setAttribute('aria-expanded', 'false');

      document.body.style.overflow = 'auto'
    }
  },
  components: {
    MenuItem,
  },
  mounted() {
		// Check if its mobile view
		if(window.innerWidth < 1024){
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    window.addEventListener('resize', () => {
      if(window.innerWidth < 1024) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }
});
